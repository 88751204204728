<template>
  <div class="print-signature">
    <span class="name">有限会社イセヤ</span><br />
    登録番号：T9320002018215<br />
    〒{{ IseyaZip }}<br />
    {{ IseyaAddress }}<br />
    TEL：{{ IseyaTel }}<br />
    FAX：{{ IseyaFax }}<br />
  </div>
</template>

<script>
import Const from "@/module/const";
export default {
  name: "printSignature",
  computed: {
    IseyaZip: function() {
      return Const.IseyaZip;
    },
    IseyaAddress: function() {
      return Const.IseyaAddress;
    },
    IseyaTel: function() {
      return Const.IseyaTel;
    },
    IseyaFax: function() {
      return Const.IseyaFax;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.print-signature {
  .name {
    font-size: 1.2rem;
  }
}
</style>
