<template>
  <div>
    <Header />
    <pageTitle :txt="ttl + ' ' + departureDate + '出荷分'" :backBtn="backBtn" />
    <div id="wrapper">
      <ul class="list" v-if="created">
        <li
          class="list-item"
          v-for="shop in shops"
          :key="shop.id"
          v-show="shop.total_price > 0"
        >
          <div class="list-item__data col-date">
            {{ shop.name }}
          </div>
          <div class="list-item__data col-btn">
            <router-link
              :to="{
                path: '/iseya/shipping/deliver/detail/',
                query: {
                  date: $route.query.date,
                  shopName: shop.name,
                  shopID: shop.id
                }
              }"
            >
              <btnS class="edit" btnTxt="詳細" color="positive1" />
            </router-link>
            <div
              @click="
                (print = !print),
                  (overlay = !overlay),
                  setPrintShop(shop.id),
                  setPrint()
              "
              class="print-btn"
            >
              <btnS class="print" btnTxt="印刷" color="primary" />
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div id="overlay" :class="{ visible: overlay }">
      <div
        class="print-paper portrait"
        v-for="paper in this.printNum"
        :key="paper"
      >
        <div class="print-inner" v-if="overlay">
          <div id="print-meta">
            <p class="print-ttl">納品書</p>

            <p class="print-period">納品日：{{ deliveryDate }}</p>

            <div class="print-meta__right">
              <div class="print-time">
                発行日：{{ today }}
                <br />
                {{ paper }}/{{ printNum }}頁
                <br />
                <br />
              </div>
              <print-signature />
            </div>


            <p class="print-address">
              〒{{ detail.zipcode }}<br />
              {{ detail.address }}<br />
              {{ detail.yago }} 御中
            </p>
          </div>

          <ul class="list no-shrink">
            <li class="list-head">
              <div class="list-head__item col-name">商品名</div>
              <div class="list-head__item col-data-1">単価</div>
              <div class="list-head__item col-data-1">数量</div>
              <div class="list-head__item col-data-1">単位</div>
              <div class="list-head__item col-data-1">税区分</div>
              <div class="list-head__item col-data-2">税抜金額</div>
            </li>

            <div
              class="list-item"
              v-for="product in detail.items"
              :key="product.id"
              v-show="product.total_num > 0"
            >
              <li>
                <div class="list-item__data col-name">
                  {{ product.item_name }}
                </div>
                <div class="list-item__data col-data-1 right">
                  {{ product.price | comma }}
                </div>
                <div class="list-item__data col-data-1 right">
                  {{ product.total_num | comma }}
                </div>
                <div class="list-item__data col-data-1 right">
                  {{ product.unit }}
                </div>
                <div class="list-item__data col-data-1 right">
                  {{ product.tax_class }}%
                </div>
                <div class="list-item__data col-data-2 right">
                  {{ product.total_price | comma }}
                </div>
              </li>

              <li v-if="product.bacuum_num > 0">
                <div class="list-item__data col-name">
                  《上記真空代》
                </div>
                <div class="list-item__data col-data-1 right">
                  {{ product.bacuum_price | comma }}
                </div>
                <div class="list-item__data col-data-1 right">
                  {{ product.bacuum_num | comma }}
                </div>
                <div class="list-item__data col-data-1 center">
                  袋
                </div>
                <div class="list-item__data col-data-1 right">
                  10%
                </div>
                <div class="list-item__data col-data-2 right">
                  {{ (product.bacuum_price * product.bacuum_num) | comma }}
                </div>
              </li>
            </div>

            <li v-if="overlay">
              <div class="list-item__data col-name">
                送料
              </div>
              <div class="list-item__data col-data-1 right">
                {{
                  ((detail.send_price / 1.1) | comma)
                }}
              </div>
              <div class="list-item__data col-data-1 right">
                {{ detail.items[0].total_box_num | comma }}
              </div>
              <div class="list-item__data col-data-1 right">
                箱
              </div>
              <div class="list-item__data col-data-1 right">
                10%
              </div>

              <div class="list-item__data col-data-2 right">
                {{
                  (((detail.send_price / 1.1) | comma) *
                    detail.items[0].total_box_num)
                    | comma
                }}
              </div>
            </li>
          </ul>

          <ul class="list invoice no-shrink">
            <li class="invoice-title">TOTAL</li>
            <li class="list-item has-border-top">
              <div class="list-item__data invoice-name">8% 対象額</div>
              <div class="list-item__data invoice-data">
                {{ detail.total.eight.total_price | comma }}
              </div>

              <div class="list-item__data invoice-name">8% 消費税</div>
              <div class="list-item__data invoice-data">
                {{ detail.total.eight.tax_price | comma }}
              </div>

              <div class="list-item__data invoice-name">合計</div>
              <div class="list-item__data invoice-data">
                {{ detail.total.eight.total_tax_price | comma }}
              </div>
            </li>
            <li class="list-item">
              <div class="list-item__data invoice-name">10% 対象額</div>
              <div class="list-item__data invoice-data">
                {{ detail.total.ten.total_price | comma }}
              </div>

              <div class="list-item__data invoice-name">10% 消費税</div>
              <div class="list-item__data invoice-data">
                {{ detail.total.ten.tax_price | comma }}
              </div>

              <div class="list-item__data invoice-name">合計</div>
              <div class="list-item__data invoice-data">
                {{ detail.total.ten.total_tax_price | comma }}
              </div>
            </li>
            <li class="list-item total">
              <div class="list-item__data invoice-name">合計</div>
              <div class="list-item__data invoice-data">
                {{ detail.total.total.total_price | comma }}
              </div>

              <div class="list-item__data invoice-name">消費税合計</div>
              <div class="list-item__data invoice-data">
                {{ detail.total.total.tax_price | comma }}
              </div>

              <div class="list-item__data invoice-name">総額</div>
              <div class="list-item__data invoice-data">
                {{ detail.total.total.total_tax_price | comma }}
              </div>
            </li>

          </ul>
        </div>
      </div>

      <div id="fixedMenu" v-if="print">
        <div
          v-on:click="(print = !print), (overlay = !overlay), removeSetPrint()"
        >
          <btnS btnTxt="戻る" color="back" />
        </div>
        <div onclick="print()">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#meta {
  width: 100%;
  height: 75px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.print-btn {
  margin-left: 30px;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 160px;
}

.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  width: 751px;
  max-width: 100%;
  margin: 0 auto 20px;
  border-left: solid 1px variables.$bg-gray-2;
}

.list-head {
  display: flex;
  height: 60px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  box-sizing: border-box;
}

.list li {
  display: flex;
  flex-wrap: wrap;
  height: 50px;
  position: relative;
  width: 100%;
  &:nth-of-type(even) {
    //background: variables.$bg-gray-1;
  }
  &.has-border-top {
    border-top: solid 1px variables.$txt-default;
  }

  &.total {
    background: variables.$bg-gray-1;
    &.visible {
      display: flex;
    }
    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: variables.$txt-default;
      position: absolute;
      top: -2px;
      left: 0;
    }
  }
}

.list-item__data {
  box-sizing: border-box;
  padding: 0 15px;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  align-items: center;
  &.number {
    justify-content: flex-end;
  }
  &.negative {
    color: variables.$negative;
  }
  &.sunday,
  &.holiday {
    color: variables.$sunday;
  }
  &.saturday {
    color: variables.$saturday;
  }
  &.right {
    justify-content: flex-end;
  }
  &.center {
    justify-content: center;
  }
}

.col-date {
  width: 300px;
}

.col-btn {
  width: 450px;
  justify-content: flex-end;
}

.list.invoice {
  border-bottom: solid 1px variables.$txt-default;
}
.invoice-title {
  max-width: calc(100% / 6);
  max-height: 40px;
  background: variables.$txt-default;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoice-name,
.invoice-data {
  width: calc(100% / 6);
  justify-content: flex-end;
}

.invoice-name {
  background: variables.$bg-gray-1;
  border-left: solid 1px variables.$txt-default;
}

.invoice-data {
  &:last-of-type {
    border-right: solid 1px variables.$txt-default;
  }
}



#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  box-sizing: border-box;
  padding: 30px 0 120px;
  overflow: scroll;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
.print-paper {
  .list {
    //width: 611px;
    width: 566px;
    transform-origin: top left;
    font-size: 1rem;
    &.invoice {
      margin-top: 30px;
    }
  }
  .list-head,
  .list li {
    font-size: 1rem;
    height: 30px;
    position: relative;
    width: 100%;
  }
  .list-head__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px #dfdfdf;
    border-bottom: solid 1px variables.$bg-gray-2;
    border-top: solid 1px #dfdfdf;
    box-sizing: border-box;
  }
  .col-name {
    //width: 170px;
    width: 210px;
  }
  .col-data-1 {
    //width: 60px;
    width: 65px;
  }

  .col-data-2 {
    //width: 65px;
    width: 95px;
  }

  .col-note {
    width: 120px;
    font-size: 0.8rem;
    line-height: 1.25;
  }
}

#print-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#print-meta {
  margin-bottom: 30px;
}

.print-meta__right {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
}

.print-time {
  text-align: right;
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 60px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}

#add,
#edit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  box-sizing: border-box;
  width: 800px;
  padding: 30px 50px 50px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-form {
  display: flex;
  flex-wrap: wrap;
}

.dialog-form__item {
  @extend .f_caption;
  margin-top: 35px;
  &.name {
    width: 220px;
  }
  &.tax {
    width: 160px;
  }
  &.price {
    width: 120px;
  }
  &.note {
    width: 400px;
  }
  &.name,
  &.tax {
    margin-right: 20px;
  }
  label {
    margin-bottom: 5px;
    display: block;
  }
  input,
  select {
    box-sizing: border-box;
    border: solid 1px variables.$bg-gray-3;
    border-radius: 3px;
    background: #fff;
    width: 100%;
    height: 28px;
    padding: 0 12px;
  }
  .select {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: variables.$txt-default transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }
  }
}

.overlay-btn {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  position: relative;
  margin: 0 auto;
  .buttonS {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}
.pager-wrap {
  width: 900px;
  margin: 0 auto;
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";
import Const from "@/module/const";
import PrintSignature from '../../../components/iseya/printSignature.vue';

export default {
  name: "deliverShop",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL,
    PrintSignature
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      print: false,
      today: "",
      overlay: false,
      created: false,
      ttl: "店舗別 納品書",
      printDate: "",
      printName: "",
      selectedDate: null,
      shops: [],
      products: [],
      detail: {},
      rowNum: 1, //店舗数 / カラム数
      dataPerRow: 8, //1ページあたりのカラム数
      dataPerPage: 20, //1ページあたりのデータ数
      pagePerItems: 1, //商品1周分で何枚必要か
      printNum: 1, //印刷枚数 = 商品数 / dataPerPage * rowNum
      deliveryDate: "", // 納品日
      departureDate: "" //出荷日
    };
  },
  async created() {
    console.log("created");
    this.selectedDate = new Date(this.$route.query.date);
    const api = new ApiClient();
    const result = await api.get("/iseya/store/list/s");
    this.shops = result.list;

    this.departureDate = this.ymdJp(new Date(this.$route.query.date));
    // 該当日の合計金額取得
    const param = {
      date: this.ymdFormat(this.selectedDate)
    };
    const sales = await api.post("/iseya/sale/shop", param);
    for (let i = 0, lenI = this.shops.length; i < lenI; i++) {
      for (let j = 0, lenJ = sales.list.length; j < lenJ; j++) {
        if (this.shops[i].id == sales.list[j].store_id) {
          this.shops[i].total_price = sales.list[j].total_price;
          break;
        }
      }
    }

    console.log(this.shops);
    this.created = true;

    //今日の日付
    let getDate = new Date();
    let y = getDate.getFullYear();
    let m = getDate.getMonth() + 1;
    let d = getDate.getDate();
    this.today = y + "年" + m + "月" + d + "日";
  },
  computed: {
    IseyaZip: function() {
      return Const.IseyaZip;
    },
    IseyaAddress: function() {
      return Const.IseyaAddress;
    },
    IseyaTel: function() {
      return Const.IseyaTel;
    },
    IseyaFax: function() {
      return Const.IseyaFax;
    }
  },
  methods: {
    async setPrintShop(store_id) {
      console.log("setPrintShop");
      this.selectedDate = new Date(this.$route.query.date);
      const param = {
        date: this.$route.query.date,
        store_id: store_id
      };
      const api = new ApiClient();
      const result = await api.post("/iseya/delivery/store", param);
      if (result.list.length == 0) {
        alert("注文が登録されていません。");
        return;
      }
      this.detail = result.list[0];
      this.products = result.list[0].items.filter(item => item.total_num != 0);
      this.deliveryDate = result.delivery_date;
      this.printNum = Math.ceil(this.products.length / this.dataPerPage);
      this.pagePerItems = this.detail.items.length / this.dataPerPage;
    },
    setPrint() {
      let html = document.querySelector("html");
      html.classList.add("print-portrait");
      document.body.classList.add("print-portrait");
    },
    removeSetPrint() {
      let html = document.querySelector("html");
      html.classList.remove("print-portrait");
      document.body.classList.remove("print-portrait");
    }
  }
};
</script>
